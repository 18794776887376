import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq">

      <Container>
        <div className="text-center">
          <h4 className="heading-h2"><span className="heading-h3"><span className="bluecolor">FAQ</span>  </span>
            Frequently Asked Questions
          </h4>
        </div>

        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                  How does having a Decentralized Exchange help me financially?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">To be able to run the operations smoothly, the owner of the Decentralized Exchange needs to be rewarded accordingly. For this, there is an inbuilt function that deducts commission or fee whenever the exchange users trade with each other. This commission is added to the owner’s account.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                  Do the users need to verify themselves before using the Decentralized Exchange?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">KYC verification is optional for a Decentralized Exchange. Since most of the transactions happen peer-to-peer, there is a greater trust level. Nonetheless, this feature can be incorporated for a greater sense of security.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                  Is getting a Decentralized Exchange developed an expensive undertaking?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">Not necessarily, although, how much money you eventually spend depends on the features and capabilities of your Decentralized Exchange chip.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                  Do you develop just the back-end for the Decentralized Exchange or do you also develop the interface?
                </Accordion.Toggle>

              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">When you hire us as your Decentralized Exchange development company we deliver you the complete solution including designing, creating an interface and building the back end.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle" variant="link" eventKey="4">
                  How much time does it take to deploy a fully functional Decentralized Exchange?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">Anywhere between two weeks to two months. Lots of factors can impact the time it takes to develop your Decentralized Exchange. Once we have a detailed talk with you, we will have a fair idea of the tentative timeframe. After getting a list of features we will be able to present you time duration to deliver a fully functional Decentralized Exchange.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '5' ? 'panel-wrap active-panel card mb-0' : 'panel-wrap card mb-0'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('5')} className="panel-toggle" variant="link" eventKey="5">
                  Can a Decentralized Exchange be used internationally?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="5">
                <div className="panel-body card-body">Yes, people who register with your Decentralized Exchange will be able to trade NFTs, crypto coins and other digital assets across borders. Once money and valuables have been converted into tokens and crypto coins, they don’t fall under the purview of conventional currency.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection