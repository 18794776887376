import React, { Component } from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


class CoreFeatures extends Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove">

        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor"> Core Features</span> Provided By Our </span>
              Decentralized Exchange Development Software</h3>
          </div>
          <div className="row">

            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role='presentation'>
                <li className="current" role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab1')}>
                  Connect Wallet
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab2')} >
                  Farms
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab3')} >
                  Multilingual
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab4')} >
                  Syrup Pools
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab5')} >
                  Day/Night Mode
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab6')} >
                  Rebalancing of pool ratios
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab7')} >
                  Community revenue sharing
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab8')} >
                  API Documentation
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab9')} >
                  LP (Liquidity Pool) Tokens
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab10')} >
                  Purely Decentralized
                </li>

              </ul>

              <div className="tab-content">
                <div id="tab1" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Connect Wallet
                        </h4>
                        <p className="pharagraph">Our Decentralized Exchange software allows users to connect several wallets including Metamask and Walletconnect. Once connected users can swap their Ethereum-based tokens easily and securely.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/connect-wallet.png" alt="Connect Wallet image1" />
                    </div>
                  </div>
                </div>
                <div id="tab2" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Farms </h4>
                        <p className="pharagraph">Liquidity providers get LP tokens for providing liquidity and these LP tokens can be staked in respective farms to earn rewards.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/farms.png" alt="Farms image1" />
                    </div>
                  </div>
                </div>
                <div id="tab3" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4"> Multilingual </h4>
                        <p className="pharagraph">Our system has the capability of multi-lingual support which makes it more effective for global competition. So, you can attract several NFT traders from various parts of the world.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/multilingual-support.png" alt="Multilingual image1" />
                    </div>
                  </div>
                </div>
                <div id="tab4" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Syrup Pools
                        </h4>
                        <p className="pharagraph">Syrup pools are one of the DeFi earning modules where users can stake their preferred tokens in the respective token pools for a specific time to earn a high income.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/syrup-pools.png" alt="Syrup Pools image1" />
                    </div>
                  </div>
                </div>
                <div id="tab5" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Day/Night Mode</h4>
                        <p className="pharagraph">Night mode reduces the luminance emitted by device screens and helps pleasant viewing experience while users can also use the traditional light mode to get a professional look.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/day-night-mode.png" alt="Day/Night Mode image1" />
                    </div>
                  </div>
                </div>
                <div id="tab6" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Rebalancing Of Pool Ratios</h4>
                        <p className="pharagraph">Liquidity pools are rebalanced in real time by adjusting the price between them through smart contracts. This guarantees seamless liquidity for crypto investors. </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/rebalancing-of-pool-ratios.png" alt="Rebalancing of pool ratios image1" />
                    </div>
                  </div>
                </div>
                <div id="tab7" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Community Revenue Sharing</h4>
                        <p className="pharagraph">The trade commission is shared also with the liquidity providers along with the admin. So revenue is shared within the community to attract users and also motivate them to provide liquidity for all pairs. </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/community-revenue-sharing.svg" alt="Community revenue sharing image1" />
                    </div>
                  </div>
                </div>
                <div id="tab8" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">API Documentation</h4>
                        <p className="pharagraph">Our Decentralized Exchange platform API is created for programmers who want to connect the documentation to their exchanges such as live market data, Liquidity, trade volumes and so on.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/api-documentation.svg" alt="API Documentation image1" />
                    </div>
                  </div>
                </div>
                <div id="tab9" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">LP (Liquidity Pool) Tokens</h4>
                        <p className="pharagraph">Users earn LP tokens to provide liquidity for selected pairs in the liquidity pool. These tokens can be staked in farms and users can earn more token rewards.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/liqued-tokens.png" alt="LP (Liquidity Pool) Tokens image1" />
                    </div>
                  </div>
                </div>
                <div id="tab10" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Purely Decentralized </h4>
                        <p className="pharagraph">Elimination of the traditional trade system like register, login & document verification makes our  DEX development stay anonymous. It also saves you time by just connecting the wallets and swapping the tokens.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  right-side order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/purely-decentralized.svg" alt="Purely Decentralized image1" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CoreFeatures