import React from 'react'
// import more from "../../images/defiexchange/defi-exchange.svg"



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3">Overview of </span>
              Decentralized Exchange Development</h2>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-lg-8 col-md-12 left-side order2">
              <p className="pharagraph" >DeFi stands for decentralized finance. It is a blockchain-based finance that bypasses financial intermediaries such as banks and brokerages and instead, uses smart contracts on blockchains. It also facilitates the exchange of financial instruments, lending protocols and synthetic derivatives. It is a movement that champions decentralization above everything else and promises great potential to draw investors to the exchange. It is made up of a multitude of non-custodial financial products that are highly lucrative crypto projects that have caught the eye of top multinational companies and venture capitalists.
              </p>
            </div>
            <div className="col-lg-4 col-md-12 right-side order1" >
              <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/defi-exchange.svg" alt="DeFi Exchange image1" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Whatis