import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


const BannerSection = () => {

    return (
      <section className="banner">
        <div className="container text-center">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12  text-center">
            <h1 className="main-heading">Decentralized Exchange Development Company</h1>
            <p className="sub-heading">Get your very own Decentralized Exchange and unleash a bunch of revenue streams. World-class Decentralized Exchange development services to drive your business to digital success.  </p>
            <p className="sub-small">A robust Decentralized Exchange platform to tap into the thriving decentralized finance market.</p>
          </div>  
          <div className="quick text-center">
            <img  width="983px" height="579px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/defi-exchange-development-company.png" alt="Decentralized Exchange Development Company image1" />
          </div>
          <p className="pharagraph">A platform that automatically matches buyers, and sellers to facilitate the safest transactions.
          </p>
          <div className="text-center mt-4">
            <ButtonComponent />
          </div>
        </div>
      </section>
    )
}

export default BannerSection