import React from 'react'



class UsecaseOf extends React.Component {


  render() {

    return (
      <section className="usecase">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor"> Use Case</span> of our</span>
              Decentralized Exchange Development</h3>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12  text-center" >
              <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/usecase-of-defi-exchange.svg" alt="Use Case of DeFi Exchange Development image1" />
            </div>
            <div className="col-lg-6 col-md-12">
              <p className="pharagraph" ><b>Asset management -</b>  High-value assets can be sold and bought after converting them into tokens. All their property values and definitions are stored in the code written for smart contracts.
              </p>
              <p className="pharagraph" ><b>Compliance -</b> Legal documents can be exchanged between stakeholders using the Decentralized Exchange.
              </p>
              <p className="pharagraph" ><b>Copyright contracts -</b>  A Decentralized Exchange can be a good instrument for defining, preserving, and honouring copyright contracts.
              </p>
              <p className="pharagraph" ><b>Payment solutions -</b>  Sidestepping from conventional financial institutions, a Decentralized Exchange can be used as a reliable and trustworthy peer-to-peer payment solution.
              </p>
              <p className="pharagraph" ><b>Digital identity -</b>  A Decentralized Exchange can be used to preserve and maintain one’s digital identity because once an identity is coded into a smart contract, it is practically impossible to alter it.
              </p>
              <p className="pharagraph" ><b>NFT Marketplace -</b> A digital exchange can be used as a thriving NFT Marketplace to create revenue opportunities both for the exchange owner as well as buyers and sellers.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default UsecaseOf