import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'

class WhyShould extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    return (
      <section className="whyshould">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3">Why Should You opt for</span>
              Decentralized Exchange development?
              </h2>
              <p className="pharagraph">
              </p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12  text-center" >
              <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/why-should-start-defi-exchange.svg" alt="Why Should Start NFT Marketplace image1" />
            </div>
            <div className="col-lg-6 col-md-12">
              <ul>
                <li className="pharagraph">A typical Decentralized Exchange is fast, transparent, and allows its users to have complete control over their accounts and assets.</li>
                <li className="pharagraph">This makes Decentralized Exchanges a rage among enthusiasts who want to trade in cryptocurrencies, tokens and crypto coins.
                </li>
                <li className="pharagraph">It is also one of the fastest ways of carrying out financial transactions that are as secure as technologically possible. </li>
                <li className="pharagraph">This is because at the core of a Decentralized Exchange, there reside smart contracts that have order matching, transactions and the flows of funds hardcoded into them. </li>
                <li className="pharagraph"> It is very difficult to change them or meddle with them.  </li>
                <li className="pharagraph">  Decentralized exchange development is a great way to generate revenue for yourself or your organization. The exchange users carry out transactions so that you can charge a commission or a margin per transaction.  </li>
              </ul>

              <div className="banner-btn mt-4">
                <ButtonComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyShould