import React from 'react'


const WhyChoose = () => {


  return (
    <section className="choosedesire ychoose otc">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">What Makes Coinsclone </span>The Best</span>
              Decentralized Exchange Development Company?</h3>
            <p className="pharagraph head">
            </p>
          </div>
        </div>
        <div className="row table-content firstblck">
          <div className="col-lg-6 col-md-12" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/one-stop-solution.svg" alt="One-stop solution image1" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                  One-stop solution
                </h3>
                <p className="pharagraph">
                From analysis to design & development, we can provide you with a complete Decentralized Exchange development solution from a single source.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/world-class-experience.svg" alt="World-class experience image1" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                  World-class experience
                </h3>
                <p className="pharagraph">
                  Our development team has been developing, deploying and maintaining crypto exchanges and blockchain projects for many years for clients from all over the world.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row table-content firstblck">
          <div className="col-lg-6 col-md-12" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/whitelabel-script.svg" alt="Whitelabel script image1" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                  Whitelabel script
                </h3>
                <p className="pharagraph">
                  The script that we deploy and develop for you will be fully whitelabel, which means, you will be able to alter the code in any manner you feel like or whatever is your requirement.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/custom-features.svg" alt="Custom features image1" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                  Custom features
                </h3>
                <p className="pharagraph">
                  In case you want us to develop features that are not present in any mainstream Decentralized Exchange, we are quite open to the idea.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row table-content lastblck">
          <div className="col-lg-6 col-md-12" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/transparency.svg" alt="Transparency image1" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                  Transparency
                </h3>
                <p className="pharagraph">
                  You will never be caught by surprise when you work with us. You will get a clearly-defined estimate vis-à-vis pricing and timeline and we are known to stick to our schedule.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/defiexchange/customization-and-branding.svg" alt="Customization and branding image1" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                  Customization and branding
                </h3>
                <p className="pharagraph">
                The Decentralized Exchange that we deploy for you will be completely customized and branded according to your business. That will become your product completely. 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyChoose